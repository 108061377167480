<template>
  <div>
    <div class="app-modal__box" :class="mode ? '' : 'performance__night'">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p
            class="large--title m-0"
            :class="mode ? 'text__day2' : 'text__night2'"
          >
            {{ $t("message.graphic") }}
          </p>
          <crm-store-update-close
            :permission="$options.name"
            :button_type="'store'"
            :loading="loadingButton"
            @c-submit="submit(true)"
            @c-close="close()"
          ></crm-store-update-close>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0" v-loading="loading">
        <div
          class="timeline-items__right rounded-sm w-100 p-4"
          :class="mode ? 'table__myday' : 'table__mynight'"
        >
          <el-form ref="form" status-icon :model="form" :rules="rules">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.nameGiven") }}
              </span>
              <el-form-item prop="name">
                <el-input
                  size="medium"
                  v-model="form.name"
                  autocomplete="off"
                  :class="mode ? 'input__day' : 'input__night'"
                ></el-input>
              </el-form-item>
            </div>
            <div class="app-form__group mb-4">
              <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span
                      :class="mode ? 'text__day2' : 'text__night2'"
                      class="input--label d-block mb-2"
                    >
                      {{ $t("message.branch") }}
                    </span>
                    <el-form-item prop="branch_id">
                      <select-branch
                        :id="form.branch_id"
                        :value="form.branch_id"
                        v-model="form.branch_id"
                      >
                      </select-branch>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span
                      :class="mode ? 'text__day2' : 'text__night2'"
                      class="input--label d-block mb-2"
                    >
                      {{ $t("message.graphicType") }}
                    </span>
                    <el-form-item prop="graphic_type_id">
                      <select-graphicType
                        :id="form.graphic_type_id"
                        :size="'medium'"
                        :placeholder="columns.graphic_type_id.title"
                        v-model="form.graphic_type_id"
                      ></select-graphicType>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <div class="input--label mb-2">
                      {{ $t("message.is_free") }}
                    </div>
                    <el-switch v-model="form.is_free" value="1"> </el-switch>
                  </div>
                </el-col>
              </el-row>
            </div>
            <div v-if="!form.is_free">
              <div v-if="form.schedules && form.schedules.length > 0">
                <div
                  class="crm-scha"
                  v-for="(day, index) in form.schedules"
                  :key="index + 'day'"
                >
                  <div class="titles">
                    {{ day.day }}
                  </div>

                  <div class="if-v" v-if="day.is_working_day">
                    <span v-if="day.is_working_day" class="otdo">
                      {{ $t("message.daily") }}
                    </span>
                    <div class="el-time_ruls w100s ">
                      <el-time-select
                        required
                        :placeholder="$t('message.from')"
                        v-model="day.clock_in"
                        :picker-options="{
                          start: '00:00',
                          step: '00:30',
                          end: '24:00'
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.clock_in == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>

                    <div class="el-time_ruls w100s">
                      <el-time-select
                        :placeholder="$t('message.before')"
                        v-model="day.clock_out"
                        :picker-options="{
                          start: '00:00',
                          step: '00:30',
                          end: '24:00',
                          minTime: day.clock_in
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.clock_out == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>
                    <span v-if="day.is_working_day" class="otdo">
                      {{ $t("message.Break") }}
                    </span>
                    <div class="el-time_ruls w100s">
                      <el-time-select
                        :placeholder="$t('message.from')"
                        v-model="day.break_start_time"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '24:00'
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.break_start_time == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>

                    <div class="el-time_ruls w100s">
                      <el-time-select
                        :placeholder="$t('message.before')"
                        v-model="day.break_end_time"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '24:00',
                          minTime: day.break_start_time
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.break_end_time == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>
                  </div>
                  <el-switch
                    class="ml-2"
                    v-model="day.is_working_day"
                    @change="empty(day)"
                  >
                  </el-switch>
                </div>
              </div>
              <div v-else>
                <div
                  class="crm-scha"
                  v-for="(day, index) in schedules"
                  :key="index + 'day'"
                >
                  <div class="titles">
                    {{ day.day }}
                  </div>

                  <div class="if-v" v-if="day.is_working_day">
                    <span v-if="day.is_working_day" class="otdo">
                      {{ $t("message.daily") }}
                    </span>
                    <div class="el-time_ruls w100s">
                      <el-time-select
                        required
                        :placeholder="$t('message.from')"
                        v-model="day.clock_in"
                        :picker-options="{
                          start: '00:00',
                          step: '00:30',
                          end: '24:00'
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.clock_in == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>

                    <div class="el-time_ruls w100s">
                      <el-time-select
                        :placeholder="$t('message.before')"
                        v-model="day.clock_out"
                        :picker-options="{
                          start: '00:00',
                          step: '00:30',
                          end: '24:00',
                          minTime: day.clock_in
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.clock_out == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>

                    <span v-if="day.is_working_day" class="otdo">
                      {{ $t("message.Break") }}
                    </span>

                    <div class="el-time_ruls w100s">
                      <el-time-select
                        :placeholder="$t('message.from')"
                        v-model="day.break_start_time"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '24:00'
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.break_start_time == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>

                    <div class="el-time_ruls w100s">
                      <el-time-select
                        :placeholder="$t('message.before')"
                        v-model="day.break_end_time"
                        :picker-options="{
                          start: '00:00',
                          step: '00:15',
                          end: '24:00',
                          minTime: day.break_start_time
                        }"
                        :class="mode ? 'input__day' : 'input__night'"
                      >
                      </el-time-select>
                      <span class="rulus" v-if="day.break_end_time == null">
                        {{ $t("message.language_graphic_type") }}
                      </span>
                    </div>
                  </div>

                  <el-switch
                    class="ml-2"
                    v-model="day.is_working_day"
                    @change="empty(day)"
                  >
                  </el-switch>
                </div>
              </div>
            </div>
            <div v-else>
              <el-row :gutter="20" class="mt-5">
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span
                      :class="mode ? 'text__day2' : 'text__night2'"
                      class="input--label d-block mb-2"
                    >
                      {{ $t("message.monthly_limit_work_hour") }}
                    </span>
                    <el-form-item prop="monthly_limit_work_hour">
                      <el-input
                        size="medium"
                        v-model="form.monthly_limit_work_hour"
                        autocomplete="off"
                        :type="'number'"
                        :class="mode ? 'input__day' : 'input__night'"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-col>
                <el-col :span="12">
                  <div class="app-form__group mb-4">
                    <span
                      :class="mode ? 'text__day2' : 'text__night2'"
                      class="input--label d-block mb-2"
                    >
                      {{ $t("message.monthly_limit_work_day") }}
                    </span>
                    <el-form-item prop="monthly_limit_work_day">
                      <el-input
                        size="medium"
                        :type="'number'"
                        :max="31"
                        v-model="form.monthly_limit_work_day"
                        autocomplete="off"
                        :class="mode ? 'input__day' : 'input__night'"
                      ></el-input>
                    </el-form-item>
                  </div>
                </el-col>
              </el-row>
              <div
                class="card-table mb-5"
                :class="mode ? 'cardtable__day' : 'cardtable__night'"
              >
                <div class="workers-info-title-za">
                  <div class="title">{{ $t("message.plane_graphic") }}</div>
                  <i class="el-icon-plus" @click="openCreatePlane"></i>
                </div>
                <div class="workers-info madal-info-workers p-5 pt-2 pb-2">
                  <table
                    class="w-100"
                    :class="mode ? 'table__myday' : 'table__mynight'"
                  >
                    <tr>
                      <td>
                        <strong
                          class="mr-1 font-bold"
                          :class="mode ? 'text__day2' : 'text__night2'"
                          >{{ $t("message.monthly") }}</strong
                        >
                      </td>
                      <td>
                        <strong
                          class="mr-1 font-bold"
                          :class="mode ? 'text__day2' : 'text__night2'"
                          >{{ $t("message.monthly_limit_work_day") }}</strong
                        >
                      </td>
                      <td colspan="2">
                        <strong
                          class="mr-1 font-bold"
                          :class="mode ? 'text__day2' : 'text__night2'"
                        >
                          {{ $t("message.monthly_limit_work_hour") }}
                        </strong>
                      </td>
                    </tr>
                    <tr
                      v-for="(plane, index) in planes"
                      :key="'child-' + index"
                    >
                      <td>
                        <p
                          :class="mode ? 'text__day2' : 'text__night2'"
                          class="
                                        text-left
                                        font-medium
                                        pb-1
                                        pt-0
                                        mb-3
                                        mt-3
                                    "
                        >
                          <span> {{ formatDate(plane.month) }} </span>
                        </p>
                      </td>

                      <td>
                        <p
                          :class="mode ? 'text__day2' : 'text__night2'"
                          class="
                                        text-left
                                        font-medium
                                        pb-1
                                        pt-0
                                        mb-3
                                        mt-3
                                    "
                        >
                          <span>{{ plane.limit_work_day }} </span>
                        </p>
                      </td>
                      <td>
                        <p
                          :class="mode ? 'text__day2' : 'text__night2'"
                          class="
                                        text-left
                                        font-medium
                                        pb-1
                                        pt-0
                                        mb-3
                                        mt-3
                                    "
                        >
                          <span>{{ plane.limit_work_hour }} </span>
                        </p>
                      </td>
                      <td class="text-right">
                        <el-row>
                          <el-button
                            type="primary"
                            icon="el-icon-edit"
                            circle
                            size="small"
                            @click="editStaffChildren(plane)"
                            v-can="'staffs.update'"
                          ></el-button>

                          <el-button
                            type="danger"
                            icon="el-icon-delete"
                            circle
                            size="small"
                            @click="deleteGraphicPlane(plane.id)"
                            v-can="'staffs.destroy'"
                          ></el-button>
                        </el-row>
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            </div>
          </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
    <!-- start Modal  -->
    <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      title="Добавить Дети"
      :visible.sync="childrenCreate"
      :append-to-body="true"
      width="60%"
      center
    >
      <el-form ref="form" :model="formDialog">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.monthly") }}
              </span>
              <el-date-picker
                :class="mode ? 'input__day' : 'input__night'"
                v-model="formDialog.month"
                type="month"
                format="MMMM yyyy"
                value-format="yyyy-MM-dd"
                :placeholder="$t('message.monthly')"
                size="medium"
                class="w-100"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.monthly_limit_work_hour") }}
              </span>
              <el-form-item prop="limit_work_hour">
                <el-input
                  size="medium"
                  v-model="formDialog.limit_work_hour"
                  autocomplete="off"
                  :type="'number'"
                  :class="mode ? 'input__day' : 'input__night'"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.monthly_limit_work_day") }}
              </span>
              <el-form-item prop="limit_work_day">
                <el-input
                  size="medium"
                  :type="'number'"
                  :max="31"
                  v-model="formDialog.limit_work_day"
                  autocomplete="off"
                  :class="mode ? 'input__day' : 'input__night'"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <!-- end col -->

          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="submitFormDialog()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="childrenCreate = false">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>
    <el-dialog
      :class="mode ? 'dialog__myday' : 'dialog__mynight'"
      class="ichki_modal"
      title="Добавить Дети"
      :visible.sync="childrenUpdate"
      :append-to-body="true"
      width="60%"
      center
    >
      <el-form ref="form" :model="formDialog">
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.monthly") }}
              </span>
              <el-date-picker
                :class="mode ? 'input__day' : 'input__night'"
                v-model="formDialog.month"
                type="month"
                format="MMMM yyyy"
                value-format="yyyy-MM-dd"
                :placeholder="$t('message.monthly')"
                size="medium"
                class="w-100"
              >
              </el-date-picker>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.monthly_limit_work_hour") }}
              </span>
              <el-form-item prop="limit_work_hour">
                <el-input
                  size="medium"
                  v-model="formDialog.limit_work_hour"
                  autocomplete="off"
                  :type="'number'"
                  :class="mode ? 'input__day' : 'input__night'"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <!-- end col -->

          <el-col :span="12">
            <div class="app-form__group mb-4">
              <span
                :class="mode ? 'text__day2' : 'text__night2'"
                class="input--label d-block mb-2"
              >
                {{ $t("message.monthly_limit_work_day") }}
              </span>
              <el-form-item prop="limit_work_day">
                <el-input
                  size="medium"
                  :type="'number'"
                  :max="31"
                  v-model="formDialog.limit_work_day"
                  autocomplete="off"
                  :class="mode ? 'input__day' : 'input__night'"
                ></el-input>
              </el-form-item>
            </div>
          </el-col>
          <!-- end col -->

          <!-- end col -->
        </el-row>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="success" size="medium" @click="updateGraphicPlanes()">
          {{ $t("message.save") }}
        </el-button>
        <el-button type="warning" size="medium" @click="childrenUpdate = false">
          {{ $t("message.close") }}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import selectGraphicType from "@/components/filters/inventory/select-graphicType";
import form from "@/utils/mixins/form";
import moment from "moment";
export default {
  mixins: [form, drawer],
  name: "GraphicController",
  components: {
    selectGraphicType
  },
  props: {
    selectedItem: {
      type: Object
    }
  },
  data() {
    return {
      formDialog: {
        limit_work_day: "",
        month: "",
        limit_work_hour: ""
      },
      staffChildren: {},
      childrenCreate: false,
      childrenUpdate: false,
      schedules: [
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 1,
          day: "Понедельник"
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 2,
          day: "Вторник"
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 3,
          day: "Среда"
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 4,
          day: "Четверг"
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 5,
          day: "Пятница"
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 6,
          day: "Суббота"
        },
        {
          is_working_day: false,
          clock_in: null,
          clock_out: null,
          break_start_time: null,
          break_end_time: null,
          day_number: 0,
          day: "Воскресенье"
        }
      ],

      loading: false
    };
  },
  created() {},
  computed: {
    ...mapGetters({
      rules: "graphic/rules",
      model: "graphic/model",
      planes: "graphic/planes",
      columns: "graphic/columns",
      mode: "MODE"
    })
  },
  methods: {
    ...mapActions({
      update: "graphic/update",
      show: "graphic/show",
      getPlanes: "graphic/getPlanes",
      addPlanes: "graphic/addPlanes",
      updatePlanes: "graphic/updatePlanes",
      deletePlanes: "graphic/deletePlanes"
    }),
    formatDate(value) {
      if (value) {
        return moment(value)
          .lang("ru")
          .format("MMMM YYYY");
      }
    },
    afterOpen() {
      this.getGraphic();
      this.getGraphicPlanes();
    },
    submit(close = true) {
      this.$refs["form"].validate(valid => {
        if (
          valid &&
          this.validateSchedules(
            this.form.schedules.length > 0
              ? this.form.schedules
              : this.schedules
          )
        ) {
          this.loadingButton = true;
          this.form.schedules =
            this.form.schedules.length > 0
              ? this.form.schedules
              : this.schedules;
          this.form.is_free = this.form.is_free ? this.form.is_free : false;
          this.update(this.form)
            .then(res => {
              this.loadingButton = false;
              this.$alert(res);
              this.parent().listChanged();
              if (res.status == 201) this.close();
            })
            .catch(err => {
              console.log("error is happening");
              this.loadingButton = false;
              this.$alert(err);
            });
        }
      });
    },
    submitFormDialog() {
      this.loadingButton = true;
      this.formDialog.graphic_id = this.selectedItem.id;
      this.addPlanes(this.formDialog)
        .then(res => {
          this.loadingButton = false;
          this.$alert(res);
          if (res.status == 201) {
            this.getGraphicPlanes();
            this.formDialog = {
              limit_work_day: "",
              month: "",
              limit_work_hour: ""
            };
            this.childrenCreate = false;
          }
        })
        .catch(err => {
          console.log("error is happening");
          this.loadingButton = false;
          this.$alert(err);
        });
    },
    validateSchedules(schedules) {
      for (let key in schedules) {
        if (schedules[key].is_working_day) {
          if (
            schedules[key].clock_in == null ||
            schedules[key].clock_out == null ||
            schedules[key].break_start_time == null ||
            schedules[key].break_end_time == null
          ) {
            return false;
          }
        }
      }
      return true;
    },
    empty(day) {
      if (!day.is_working_day) {
        (day.clock_in = null),
          (day.clock_out = null),
          (day.break_start_time = null),
          (day.break_end_time = null);
      }
    },
    getGraphic() {
      if (!this.loading) {
        this.loading = true;
        this.show(this.selectedItem.id)
          .then(res => {
            this.loading = false;
          })
          .catch(err => {
            this.loading = false;
            this.$alert(err);
          });
      }
    },
    getGraphicPlanes() {
      this.getPlanes(this.selectedItem.id)
        .then(res => {
          this.loading = false;
        })
        .catch(err => {
          this.loading = false;
          this.$alert(err);
        });
    },
    editStaffChildren(child) {
      this.formDialog = JSON.parse(JSON.stringify(child));
      this.childrenUpdate = true;
    },
    updateGraphicPlanes() {
      this.loadChildren = true;
      this.updatePlanes(this.formDialog)
        .then(res => {
          this.$alert(res);
          if (res.status == 201) {
            this.getGraphicPlanes();
            this.formDialog = {
              limit_work_day: "",
              month: "",
              limit_work_hour: ""
            };
            this.loadChildren = false;
            this.childrenUpdate = false;
          }
        })
        .catch(err => {
          this.$alert(err);
          this.loadChildren = false;
        });
    },
    deleteGraphicPlane(id) {
      this.deletePlanes(id)
        .then(res => {
          this.$alert(res);
          this.getGraphicPlanes();
          this.loadChildren = false;
        })
        .catch(err => {
          this.$alert(err);
          this.loadChildren = false;
        });
    },
    openCreatePlane() {
      this.formDialog = {
        limit_work_day: "",
        month: "",
        limit_work_hour: ""
      };
      this.childrenCreate = true;
    }
  }
};
</script>
<style lang="scss">
.otdo {
  min-width: 30px;
  margin-left: 20px;

  display: inline-block;
}
.crm-scha {
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #00000021;
  padding-bottom: 30px;
  margin-bottom: 20px;
  position: relative;
}
.crm-scha .titles {
  width: 120px;
}
.crm-scha .el-date-editor {
  margin-left: 10px;
}
.rulus {
  bottom: 0px;
  font-size: 10px;
  color: red;
  padding-left: 10px;
  display: block;
}
.el-time_ruls.w100s {
  position: relative;
  flex: 1;
  margin-right: 6px;
}
.el-time_ruls.w100s .el-input {
  width: 100% !important;
}
.if-v {
  flex: 1;
  display: flex;
  align-items: center;
}
.table__mynight .crm-scha {
  border-color: rgb(120, 119, 119);
}
.performance__night {
  .el-switch__core {
    background: #323248;
    border-color: rgb(187, 187, 187);
    &::after {
      background: rgb(238, 238, 238);
    }
  }
}
</style>
